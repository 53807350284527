@import "assets/styles/index.scss";

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
*:focus {
  outline: none;
}
body {
  margin: 0;
  color: #fff;
  background: $bg;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  font: 400 16px/20px $primary-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-content{
  padding-bottom: 110px;
}

.Toastify__toast-container {
  z-index: 999999999 !important;
}


.toast-success-container {
  color: #000 !important;
  border-radius: 8px !important;
  background: #FFFFFF !important;
  border: 1px solid #34A853 !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-success-container-after {
  overflow: hidden;
  position: relative;
}

.toast-success-container-after::after{
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background-color: #34A853;
}

.Toastify__close-button {
  color: #fff;
  background: rgba(0,0,0,0.16) !important;
  width: 25px;
  outline: none;
  border: none;
  padding: 0;
  border-radius: 100px !important;
  cursor: pointer;
  transition: 0.3s ease;
  align-self: center !important;
  height: 25px;
}
.Toastify__close-button > svg {
  fill: #000 !important;
  height: 16px;
  display: flex;
  width: 16px !important;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
