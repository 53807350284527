.border-box {
    //border: solid 2px transparent !important;
    //box-sizing: border-box !important;
    //border-image-slice: 48 !important;
    //border-image-width: 48px !important;
    @extend .round-24;
    &.top {
        //border-image-source: url(../../wallets/assets/images/top_border.png) !important;
    }
    &.left {
        border-image-source: url(../../wallets/assets/images/left_border.png) !important;
    }
    // &.bottom {
    //     border-image-source: url(../../wallets/assets/images/bottom_border.png);
    // }
    &.right {
        border-image-source: url(../../wallets/assets/images/right_border.png) !important;
    }
    &.land-management-left{
        border-image-source: url(../images/land-management-left-border.png) !important;
    }
    &.land-management-right{
        border-image-source: url(../images/land-management-right-border.png) !important;
    }
    &.land-management-hover-card{
        border-image-source: url(../images/land-management-hover-card.png) !important;
    }
}
