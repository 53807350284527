.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}

.fs-12{
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-24 {
  font-size: 24px;
}
.fs-28 {
  font-size: 28px;
}
.fs-32 {
  font-size: 32px;
}
.fs-36 {
  font-size: 36px;
}
.fs-40 {
  font-size: 40px;
}
.fs-48 {
  font-size: 48px;
}

.text-uppercase {
  text-transform: uppercase;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-center {
  text-align: center;
}
.margin-inline-auto{
  margin-inline: auto;
}
.text-initial {
  text-transform: initial !important;
}

.title {
  letter-spacing: -0.03rem;
  color: $white;
  margin-bottom: 1rem;

  &.lg {
    font-size: 40px;
    @media only screen and (max-width: 990px) {
      font-size: 24px;
    }
  }
  &.md {
    font-size: 36px;
    @media only screen and (max-width: 990px) {
      font-size: 24px;
    }
  }
  &.sm {
    font-size: 24px;
  }
  &.xsm {
    font-size: 20px;
    @media only screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
}

p {
  font-size: 1rem;
  font-weight: 400;
  color: $gray;
  letter-spacing: -0.03rem;
  line-height: 24px;
}

