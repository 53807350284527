.p-0 {
    padding: 0px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.p-5 {
    padding: 5px !important;
}

.pt-5 {
    padding-top: 5px !important;
}

.pr-5 {
    padding-right: 5px !important;
}

.pb-5 {
    padding-bottom: 5px !important;
}

.pl-5 {
    padding-left: 5px !important;
}

.p-10 {
    padding: 10px !important;
}

.pt-10 {
    padding-top: 10px !important;
}

.pr-10 {
    padding-right: 10px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pl-10 {
    padding-left: 10px !important;
}

.p-15 {
    padding: 15px !important;
}

.pt-15 {
    padding-top: 15px !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.p-20 {
    padding: 20px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pr-20 {
    padding-right: 20px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pl-20 {
    padding-left: 20px !important;
}

.p-25 {
    padding: 25px !important;
}

.pt-25 {
    padding-top: 25px !important;
}

.pr-25 {
    padding-right: 25px !important;
}

.pb-25 {
    padding-bottom: 25px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.p-30 {
    padding: 30px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pl-30 {
    padding-left: 30px !important;
}

.p-35 {
    padding: 35px !important;
}

.pt-35 {
    padding-top: 35px !important;
}

.pr-35 {
    padding-right: 35px !important;
}

.pb-35 {
    padding-bottom: 35px !important;
}

.pl-35 {
    padding-left: 35px !important;
}

.p-40 {
    padding: 40px !important;
}

.pt-40 {
    padding-top: 40px !important;
}

.pr-40 {
    padding-right: 40px !important;
}

.pb-40 {
    padding-bottom: 40px !important;
}

.pl-40 {
    padding-left: 40px !important;
}

.p-45 {
    padding: 45px !important;
}

.pt-45 {
    padding-top: 45px !important;
}

.pr-45 {
    padding-right: 45px !important;
}

.pb-45 {
    padding-bottom: 45px !important;
}

.pl-45 {
    padding-left: 45px !important;
}

.p-50 {
    padding: 50px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pr-50 {
    padding-right: 50px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pl-50 {
    padding-left: 50px !important;
}

.p-55 {
    padding: 55px !important;
}

.pt-55 {
    padding-top: 55px !important;
}

.pr-55 {
    padding-right: 55px !important;
}

.pb-55 {
    padding-bottom: 55px !important;
}

.pl-55 {
    padding-left: 55px !important;
}

.p-60 {
    padding: 60px !important;
}

.pt-60 {
    padding-top: 60px !important;
}

.pr-60 {
    padding-right: 60px !important;
}

.pb-60 {
    padding-bottom: 60px !important;
}

.pl-60 {
    padding-left: 60px !important;
}

.p-65 {
    padding: 65px !important;
}

.pt-65 {
    padding-top: 65px !important;
}

.pr-65 {
    padding-right: 65px !important;
}

.pb-65 {
    padding-bottom: 65px !important;
}

.pl-65 {
    padding-left: 65px !important;
}

.p-70 {
    padding: 70px !important;
}

.pt-70 {
    padding-top: 70px !important;
}

.pr-70 {
    padding-right: 70px !important;
}

.pb-70 {
    padding-bottom: 70px !important;
}

.pl-70 {
    padding-left: 70px !important;
}

.p-75 {
    padding: 75px !important;
}

.pt-75 {
    padding-top: 75px !important;
}

.pr-75 {
    padding-right: 75px !important;
}

.pb-75 {
    padding-bottom: 75px !important;
}

.pl-75 {
    padding-left: 75px !important;
}

.p-80 {
    padding: 80px !important;
}

.pt-80 {
    padding-top: 80px !important;
}

.pr-80 {
    padding-right: 80px !important;
}

.pb-80 {
    padding-bottom: 80px !important;
}

.pl-80 {
    padding-left: 80px !important;
}

.p-100 {
    padding: 100px !important;
}

.pt-100 {
    padding-top: 100px !important;
}

.pr-100 {
    padding-right: 100px !important;
}

.pb-100 {
    padding-bottom: 100px !important;
}

.pl-100 {
    padding-left: 100px !important;
}

.p-150 {
    padding: 150px !important;
}

.pt-150 {
    padding-top: 150px !important;
}

.pr-150 {
    padding-right: 150px !important;
}

.pb-150 {
    padding-bottom: 150px !important;
}

.pl-150 {
    padding-left: 150px !important;
}

.m-0 {
    margin: 0px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.ml-0 {
    margin-left: 0px !important;
}

.m-5 {
    margin: 5px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.m-10 {
    margin: 10px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.m-15 {
    margin: 15px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.m-20 {
    margin: 20px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.m-25 {
    margin: 25px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mr-25 {
    margin-right: 25px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.ml-25 {
    margin-left: 25px !important;
}

.m-30 {
    margin: 30px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.m-35 {
    margin: 35px !important;
}

.mt-35 {
    margin-top: 35px !important;
}

.mr-35 {
    margin-right: 35px !important;
}

.mb-35 {
    margin-bottom: 35px !important;
}

.ml-35 {
    margin-left: 35px !important;
}

.m-40 {
    margin: 40px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mr-40 {
    margin-right: 40px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.ml-40 {
    margin-left: 40px !important;
}

.m-45 {
    margin: 45px !important;
}

.mt-45 {
    margin-top: 45px !important;
}

.mr-45 {
    margin-right: 45px !important;
}

.mb-45 {
    margin-bottom: 45px !important;
}

.ml-45 {
    margin-left: 45px !important;
}

.m-50 {
    margin: 50px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mr-50 {
    margin-right: 50px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.ml-50 {
    margin-left: 50px !important;
}

.m-55 {
    margin: 55px !important;
}

.mt-55 {
    margin-top: 55px !important;
}

.mr-55 {
    margin-right: 55px !important;
}

.mb-55 {
    margin-bottom: 55px !important;
}

.ml-55 {
    margin-left: 55px !important;
}

.m-60 {
    margin: 60px !important;
}

.mt-60 {
    margin-top: 60px !important;
}

.mr-60 {
    margin-right: 60px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

.ml-60 {
    margin-left: 60px !important;
}

.m-65 {
    margin: 65px !important;
}

.mt-65 {
    margin-top: 65px !important;
}

.mr-65 {
    margin-right: 65px !important;
}

.mb-65 {
    margin-bottom: 65px !important;
}

.ml-65 {
    margin-left: 65px !important;
}

.m-70 {
    margin: 70px !important;
}

.mt-70 {
    margin-top: 70px !important;
}

.mr-70 {
    margin-right: 70px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}

.ml-70 {
    margin-left: 70px !important;
}

.m-75 {
    margin: 75px !important;
}

.mt-75 {
    margin-top: 75px !important;
}

.mr-75 {
    margin-right: 75px !important;
}

.mb-75 {
    margin-bottom: 75px !important;
}

.ml-75 {
    margin-left: 75px !important;
}

.m-80 {
    margin: 80px !important;
}

.mt-80 {
    margin-top: 80px !important;
}

.mr-80 {
    margin-right: 80px !important;
}

.mb-80 {
    margin-bottom: 80px !important;
}

.ml-80 {
    margin-left: 80px !important;
}

.m-100 {
    margin: 100px !important;
}

.mt-100 {
    margin-top: 100px !important;
}

.mr-100 {
    margin-right: 100px !important;
}

.mb-100 {
    margin-bottom: 100px !important;
}

.ml-100 {
    margin-left: 100px !important;
}

.m-150 {
    margin: 150px !important;
}

.mt-150 {
    margin-top: 150px !important;
}

.mr-150 {
    margin-right: 150px !important;
}

.mb-150 {
    margin-bottom: 150px !important;
}

.ml-150 {
    margin-left: 150px !important;
}

.round-8 {
    border-radius: 8px;
}
.round-12 {
    border-radius: 12px;
}
.round-16 {
    border-radius: 16px;
}
.round-20 {
    border-radius: 20px;
}
.round-24 {
    border-radius: 24px;
}
.round-36 {
    border-radius: 36px;
}
.round-100 {
    border-radius: 100px;
}
.font-white{
    color: $white;
}
.d-flex{
    display: flex;
}
.flex-align-left {
    display: flex;
    align-items: left;
}
.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.flex-align-center {
    display: flex;
    align-items: center;
}
.flex-justify-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.inline-flex-center {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.w-100 {
    width: 100%;
}
.h-100 {
    height: 100%;
}
.h-50 {
    height: 50%;
}