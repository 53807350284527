@import "../../assets/styles/variables.scss";
@import "../../assets/styles/spacing.scss";
@import "../../assets/styles/font.scss";

.loadingContainer {
    min-height: 100vh;
    @extend .flex-center, .w-100;
}
.loginMessageContainer {
    height: 100vh;
    @extend .flex-center, .w-100;
}
.loader-config-container{
    z-index: 1000000;
    min-height: 100vh;
    @extend .flex-center, .w-100;
}
.sc-loader {
    min-height: 600px;
    @extend .flex-center, .w-100;
}
.loader {
    width: 120px;
    height: 120px;
    @extend .flex-center, .round-100;
    background: $purple;
    cursor: none;
    margin-inline: auto;
    img {
        max-width: 60%;
        max-height: 60%;
    }
}
.dashboard-loader{
    .loader{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.notFoundData {
    @extend .flex-center, .text-center;
    max-width: 400px;
    min-height: calc(100vh - 185px);
    margin: 0 auto;

    .empty-icon {
        width: 100px;
        height: 100px;
        margin: 0 auto 15px;
    }

    .content {
        .title {
            @extend .sm, .text-capitalize, .mb-5;
        }
        p {
            @extend .fs-14;
        }
        .custom-btn {
            min-width: 150px;
        }
    }
}
