@import url('https://fonts.googleapis.com/css2?family=Quantico:wght@400;700&display=swap');

@import "variables.scss";
@import "buttons.scss";
@import "font.scss";
@import "spacing.scss";
@import "border.scss";
@import "card.scss";
@import "inputs.scss";


//for remove input type number arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.modal-open .asidebarContainer {
    z-index: 80 !important;
}

.wallet-modal {

    .modal,
    .modal-backdrop,
    .fixed-top {
        z-index: 80 !important;
    }
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

// end

.accentClr {
    color: $purple;
}

ul,
li {
    list-style: none;
    padding-left: 0;
}

a {
    color: $white;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

img,
.img-fluid {
    max-width: 100%;
    max-height: 100%;
}

.prime-dashboard {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 0px);
    letter-spacing: -0.03rem;

    background: url(../images/web_background.png);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    letter-spacing: -0.03rem;
}

.contentContainer .grid-layout {
    left: 320px;

    @media only screen and (max-width: 1024px) {
        display: none !important;
    }
}

.grid-layout {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;

    div:not(:last-child) {
        border-right: 1.5px solid rgba(71, 84, 103, 0.05);
    }

    @media only screen and (max-width: 990px) {
        display: none;
    }
}

.modals-close-cross {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2f2f2f;
    border: none;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 100%;
    padding: 0 !important;

    img {
        max-width: 8px;
        max-height: 8px;
    }
}

.custom-col {
    max-width: 16.66%;
    flex: 0 0 16.66%;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 24px;

    @media only screen and (min-width: 320px) and (max-width: 767px) {
        max-width: 50%;
        flex: 0 0 50%;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1400px) {
        max-width: 25%;
        flex: 0 0 25%;
    }

    @media only screen and (min-width: 1401px) and (max-width: 1600px) {
        max-width: 20%;
        flex: 0 0 20%;
    }

    .smCard {
        max-width: 100%;
    }
}

.content {
    transition: all 0.2s linear;
    width: 100%;

    &.slideRight {
        // @media only screen and (min-width: 1210px) and (max-width: 1600px) {
        //     width: calc(100% - 400px) !important;
        // }

        @media only screen and (min-width: 1201px) and (max-width: 6000px) {
            width: calc(100% - 496px);
        }

        .cardListing {
            .custom-col {
                max-width: 25%;
                flex: 0 0 25%;
            }

            @media only screen and (min-width: 320px) and (max-width: 767px) {
                .custom-col {
                    max-width: 50%;
                    flex: 0 0 50%;
                }
            }

            @media only screen and (min-width: 1024px) and (max-width: 1400px) {
                .custom-col {
                    max-width: 50%;
                    flex: 0 0 50%;
                }
            }

            @media only screen and (min-width: 1401px) and (max-width: 1600px) {
                .custom-col {
                    max-width: 33.33%;
                    flex: 0 0 33.33%;
                }
            }

            @media only screen and (min-width: 1601px) and (max-width: 1700px) {
                .custom-col {
                    max-width: 33.33%;
                    flex: 0 0 33.33%;
                }
            }

            @media only screen and (min-width: 2051px) and (max-width: 6000px) {
                .custom-col {
                    max-width: 20%;
                    flex: 0 0 20%;
                }
            }

            @media only screen and (max-width: 1200px) {
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgb(0, 0, 0, .9);
                    z-index: 1;
                }
            }


        }
    }
}

.contentContainer {
    position: relative;
    width: calc(100% - 320px);
    margin-left: 320px;
    min-height: calc(100vh - 0px);
    height: 100%;

    @media only screen and (max-width: 1200px) {
        width: calc(100% - 0px);
        margin-left: 0;
    }

    @media only screen and (min-width: 1210px) and (max-width: 1600px) {
        width: calc(100% - 270px);
        margin-left: 270px;
    }
}

.main-content {
    position: relative;
    z-index: 1;
    padding-top: 110px;
    padding-right: 32px;
    padding-left: 32px;
    min-height: calc(100vh - 0px);
    height: 100%;
    margin-right: 4px;

    @media only screen and (max-width: 1200px) {
        padding-right: 24px;
        padding-left: 24px;
    }

    @media (max-width: 768px) {
        padding-top: 150px;
    }

    .pageforehead {
        .pageforehead_top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            //margin-bottom: 25px;

            @media only screen and (min-width: 320px) and (max-width: 1200px) {
                display: inline-flex;
                justify-content: space-between;
                align-items: flex-start;
                flex-direction: column;
                width: 100%;
            }
        }

        .pageforehead_bottom {
            @extend .mb-20, .fw-700;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media only screen and (min-width: 320px) and (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;

                .title {
                    margin-bottom: 1rem !important;
                }
            }

            .title {
                @extend .fw-700;
            }

            .section-search {
                display: inline-flex;
                align-items: center;

                .custom-btn {
                    @extend .ml-15;
                }
            }
        }

        .deskHide {
            display: none;

            @media only screen and (min-width: 320px) and (max-width: 1025px) {
                display: block;
            }

        }

        .filters-holder {
            display: flex;
            //padding-bottom: 22px;
            margin-bottom: 16px;
            overflow-x: auto;
            overflow-y: hidden;

            //width: 100%;
            &.filter-main-bg {
                padding: 8px;
                background: #2C154D;
                border-radius: 100px;

                &.width-filter {
                    @media(max-width: 1200px) {
                        width: 100%;
                    }
                }

                &.width-filter-1 {
                    @media(max-width: 460px) {
                        width: 100%;
                    }
                }
            }

            @media(max-width: 1500px) {
                padding-bottom: 18px;
            }

            .custom-btn {
                //min-width: 184px;

                &:not(:last-child) {
                    margin-right: 10px;
                }

                &.active {
                    background: $purple;
                    box-shadow: none;
                    cursor: default;
                    color: $white;
                }
            }

            @media only screen and (min-width: 320px) and (max-width: 1200px) {
                // padding-bottom: 10px;
                margin-top: 10px;
                padding-bottom: 12px;

                .custom-btn {
                    height: 40px;
                    text-transform: capitalize !important;
                    min-width: fit-content;
                }
            }
        }
    }

    ::-webkit-scrollbar {
        height: 2px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: $purple;
        border-radius: 20px;
    }
}

.tag {
    margin-bottom: 20px;
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 4px 14px;
    color: $black;
    background: $tag;
    border-radius: 100px;
    width: fit-content;

    @media only screen and (max-width: 767px) {
        margin-bottom: 15px;
    }
}

.truncate {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.track-vertical {
    top: 2px;
    bottom: 2px;
    right: 2px;
    // border-radius: 20px;
    // background: transparent;
    // width: 10px !important;
    z-index: 1030;


    .thumb-vertical {
        position: relative;
        display: block;
        width: 8px !important;
        cursor: pointer;
        background: #434343;
        border-radius: 8px;
        box-shadow: inset 2px 2px 2px #9247ff, inset -2px -2px 2px #9247ff;
    }
}

.track-horizontal {
    position: absolute;
    height: 0px !important;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border-radius: 3px;

    .thumb-horizontal {
        position: relative;
        display: block;
        height: 100%;
        cursor: pointer;
        border-radius: inherit;
        background: $purple;
    }
}

.view {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: scroll;
    // padding-right: 10px;
}

// disable body overflow on mobile when sidebar is open
.hidden-overflow {
    overflow: hidden !important;
}

.modal {
    .address {
        display: inline-block;
        color: $purple;
        font-size: 18px;
        max-width: 80%;
        font-weight: 400;
        text-decoration: underline;
    }
}

.section_box {
    width: 100%;
    height: 100%;
    background: $card-light;
    padding: 24px 32px;

    @media only screen and (min-width: 320px) and (max-width: 767px) {
        padding: 10px;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        padding: 20px 24px;
    }

    &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        //margin-bottom: 32px;
        @media only screen and (max-width: 1180px) and (min-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
        }

        .box-cta {
            display: flex;
            align-items: center;
            gap: 15px;
            overflow-y: auto;

            @media only screen and (max-width: 1180px) and (min-width: 768px) {
                gap: 10px;
                overflow: auto;
                width: 100%;

                .custom-btn {
                    min-width: fit-content;
                }
            }
        }



        // @media only screen and (min-width: 767px) and (max-width: 1150px)  {
        //     flex-direction: column;
        //     align-items: flex-start;

        //     .box-cta {
        //         margin-top: 10px;
        //         width: 100%;
        //     }
        // }
    }

    &-bottom {
        position: relative;
        width: 100%;
        max-height: calc(900px - 105px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 10px;

        @media (max-width: 767px) {
            padding-right: 20px;
            padding-left: 8px;
        }
    }

    ::-webkit-scrollbar {
        width: 3px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: $purple;
        border-radius: 20px;
    }
}


// walletconnect-wrapper
.walletconnect-modal__mobile__toggle {
    border-radius: 100px !important;

    a {
        padding: 10px !important;
        cursor: pointer;
    }

    .walletconnect-modal__mobile__toggle_selector {
        background: $purple;
        border-radius: 100px !important;
    }
}

.walletconnect-search__input {
    background: #d4d5d9 !important;
    border-radius: 100px !important;
}

.walletconnect-modal__footer {
    color: $black;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (min-width: 320px) and (max-width: 767px) {
        a {
            font-size: 10px;
        }
    }
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}