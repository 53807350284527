$primary-font-family: "Poppins", sans-serif;
$secondaryFont: "Quantico", sans-serif;

$white: #ffffff;
$light-white:#c1c1c1;
$lightWhite: #F4F4F4;
$black: #1c1c1c;
$purple: #9247ff;
$muted: #475467;
$muted-text: #dddddd;
$light-green:rgba(80, 255, 97, 0.2);
$green:#50FF61;
$gray: #adadad;
$tag: #ffe3ad;
$closebtn: #2F2F2F;
$pending: #ffe665;
$fail: #ff6565;
$success: #47FF91;
$bg: #0c031a;
$dp-bg: #FF8642;
$max: #FF3A3A;
$progress-bar: linear-gradient(272.69deg, $purple -13.44%, #F7F7F7 132.63%);
$unread: #32FF5F;
$unread-bg: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #32FF5F;
$border-color:#241C30;
$token-green: #39C64F;
$token-red: #C63939;

$black-opacity-8: rgba(0, 0, 0, 0.8);
$black-opacity-7: rgba(0, 0, 0, 0.7);
$black-opacity-6: rgba(0, 0, 0, 0.6);

$white-opacity-08: rgba(255, 255, 255, 0.08);

$card-light: linear-gradient( 90deg, rgba(146, 71, 255, 0.2) 0%, rgba(71, 84, 103, 0.2) 100% );
$card-dark: #0c031a;
$land-management-card: radial-gradient(71.7% 50% at 50% 50%, #9247FF 0%, #475467 100%);
$land-management-card-hover: linear-gradient(180deg, #0C0C0C 80.47%, rgba(12, 12, 12, 0.53) 119.87%);
$craft-card-hover: rgba(12, 12, 12, 1);
$land-management: linear-gradient(90deg, rgba(146, 71, 255, 0.2) 0%, rgba(71, 84, 103, 0.2) 100%);
$btn-shadow: 0px 8px 16px rgba(146, 71, 255, 0.25);

$wallet-txt: #667085;
$option:rgba(102, 112, 133, 0.5);
;
