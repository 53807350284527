@import './variables.scss';
@import './spacing.scss';
@import './font.scss';

$btn-outline-light: linear-gradient(0deg, $black-opacity-7, $black-opacity-7), $purple;
$btn-outline-light-hover: linear-gradient(0deg, $black-opacity-6, $black-opacity-6 ), $purple;
$btn-outline-dark: linear-gradient(0deg, $black-opacity-8, $black-opacity-8), $purple;
$btn-outline-gray: rgba(255, 255, 255, 0.16);
$btn-outline-gray-hover: rgba(255, 255, 255, 0.11);
$btn-outline-dark-hover: linear-gradient(0deg, $black-opacity-7, $black-opacity-7 ), $purple;
$hover-purple: #7539cc;

button:disabled{
  cursor: not-allowed;
  opacity: 0.75
}
button.premium-user-btn:disabled{
  display: none;
}

.custom-btn {
  @extend .inline-flex-center, .round-100, .text-capitalize;
  font-size: 14px;
  font-weight: 500;
  border: 1.5px solid transparent;
  cursor: pointer;
  padding: 0 20px;
  transition: .25s all;
  text-transform: capitalize;
  text-wrap: nowrap;
  &.lg {
    height: 40px;
  }

  &.md {
    height: 36px;
    padding: 7px 20px;
  }

  &.sm {
    height: 36px;
    padding: 6px 12px;
  }

  &.primary {
    background-color: $purple;
    color: $white;
    border-color: $purple;

    &:hover {
      background-color: $hover-purple;
      box-shadow: $btn-shadow;

      @media only screen and (max-width: 990px) {
        &:hover {
          box-shadow: none;
          background-color: $purple;
        }
      }
    }
  }

  &.secondary {
    background-color: $white;
    color: $black;
    border-color: $white;

    &:hover {
      box-shadow: $btn-shadow;
    }

    @media only screen and (max-width: 990px) {
      &:hover {
        box-shadow: none;
      }
    }
  }

  &.light-green {
    background: $light-green;
    color: $green;
    @media(max-width: 767px) {
      font-size: 12px;
      height: 30px;
    }
  }

  &.primary-outline {
    background: $btn-outline-light;
    color: $white;
    border-color: $purple;

    &:hover {
      box-shadow: $btn-shadow;
      background: $btn-outline-light-hover;
    }

    @media only screen and (max-width: 990px) {
      &:hover {
        box-shadow: none;
        background: $btn-outline-light;
      }
    }
  }

  &.secondary-outline {
    background: $btn-outline-dark;
    color: $white;
    border-color: $purple;

    &:hover {
      background: $btn-outline-dark-hover;
    }

    @media only screen and (max-width: 990px) {
      &:hover {
        box-shadow: none;
        background: $btn-outline-dark;
      }
    }
  }
  &.tertiary-outline {
    background: $btn-outline-gray;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(8px);
    color: $white;
    border-color: $white;

    &:hover {
      background: $btn-outline-gray-hover;
    }

    @media only screen and (max-width: 990px) {
      &:hover {
        box-shadow: none;
        background: $btn-outline-dark;
      }
    }
  }
  &.transparent {
    background: transparent;
    color: $light-white;

    &:hover {
      background: none;
    }
  }

  &.icon-btn {

    .icon,
    svg {
      display: inline-block;
      width: 24px;
      height: 24px;
    }

    .text {
      margin-left: 8px;
    }

    @media only screen and (max-width: 990px) {

      .icon,
      svg {
        width: 16px;
        height: 16px;
      }

      .text {
        margin-left: 6px;
      }
    }
  }

  &.icon {
    width: 48px;
    height: 48px;
    background-color: transparent;
    padding: 0;

    svg,
    img {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background-color: rgba(146, 71, 255, 0.25);
      backdrop-filter: blur(5px);
    }

    @media only screen and (max-width: 990px) {
      width: 42px;
      height: 42px;

      svg,
      img {
        width: 24px;
        height: 24px;
      }

      &:hover {
        background-color: transparent;
        backdrop-filter: blur(0);
      }
    }
  }

  &.link {
    background-color: transparent;
    color: $white;
    text-decoration: none;

    &:hover {
      color: $purple;
    }

    &.dark {
      color: $purple;
    }
  }
}
